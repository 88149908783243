import React, { useState } from "react";
import { $api } from "./api";
import Numbers from "./Numbers";

function FirstPart() { 
const [data,setData]=useState({})
const [number,showNumber] = useState()
const handleAddApplication = (e) => {
    e.preventDefault();
    const newApplication = {
        experience: e.target.experience.value,
        social: e.target.social.value,
        cv: e.target.cv.value,
        appearance: e.target.appearance.value,
        verbal:e.target.verbal.value,
        bodyLanguage:e.target.bodyLanguage.value,
        eyeContact:e.target.eyeContact.value,
        specificSkills:e.target.specificSkills.value,
        flexibility:e.target.flexibility.value,
        communication:e.target.communication.value,
        teamPlayer:e.target.teamPlayer.value,
        versatility:e.target.versatility.value,
        generalTalents:e.target.generalTalents.value,
        initiative:e.target.initiative.value,
        stressResiliance:e.target.stressResiliance.value,
        timeManagement:e.target.timeManagement.value,
        motivation:e.target.motivation.value,
        disagreement: e.target.disagreement.value ,
        willingLearn: e.target.willingLearn.value,
        preparedto:e.target.preparedto.value,
        adabtability:e.target.adabtability.value,
        proffessional:e.target.proffessional.value,
        confidentaility:e.target.confidentaility.value,
        responsiveness:e.target.responsiveness.value,
        confidence:e.target.confidence.value,
        maturity:e.target.maturity.value,
        jundgement:e.target.jundgement.value,
        goalOriented:e.target.goalOriented.value,
        understandingBusiness:e.target.understandingBusiness.value,
        salaryNegotiations:e.target.salaryNegotiations.value,
        questionsAsked:e.target.questionsAsked.value,
        feedbackOpenness: e.target.feedbackOpenness.value
    };
    $api.post("https://console.decision-making.software/api/flows/publish/sc/v4", newApplication)
    .then((response) => {
       showNumber(true) 
       setData(response.data.response) 
    })
    .catch((error) => {
        console.log(error);
       
    })    
  };
    console.log(data)
    console.log(number)
  return (
<>
    {!number?(<form className="style" onSubmit={handleAddApplication}>
    <label className='input-label'>
       <span className = "filds-name">Professional Experience</span>
       <select className="filds"  id="yearFounded" name="experience" required >
         <option ></option>
         <option value="1">1</option>0
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Social Media Presence</span>
       <select className="filds"  id="yearFounded" name="social" required >
         <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">CV Professionalism</span>
       <select className="filds"  id="yearFounded" name="cv" required >
       <option value="0"></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Appearance on interview</span>
       <select className="filds"  id="yearFounded" name="appearance" required >
       <option value="0"></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Verbal communication Skills</span>
       <select className="filds"  id="yearFounded" name="verbal" required >
       <option value="0"></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Body Language used</span>
       <select className="filds"  id="yearFounded" name="bodyLanguage" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Keeping Eye Contact</span>
       <select className="filds"  id="yearFounded" name="eyeContact" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Specific skills</span>
       <select className="filds"  id="yearFounded" name="specificSkills" required >
       <option></option>
         <option value="1">More Yes</option>
         <option value="2">More No</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Flexibility</span>
       <select className="filds"  id="yearFounded" name="flexibility" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Communication skills</span>
       <select className="filds"  id="yearFounded" name="communication" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Team Player</span>
       <select className="filds"  id="yearFounded" name="teamPlayer" required >
       <option></option>
         <option value="1">More Yes</option>
         <option value="2">More No	</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Versatility</span>
       <select className="filds"  id="yearFounded" name="versatility" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">General Talents</span>
       <select className="filds"  id="yearFounded" name="generalTalents" required >
       <option></option>
         <option value="1">More Yes</option>
         <option value="2">More No	</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Initiativeness</span>
       <select className="filds"  id="yearFounded" name="initiative" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Stress Resilience</span>
       <select className="filds"  id="yearFounded" name="stressResiliance" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Time Management</span>
       <select className="filds"  id="yearFounded" name="timeManagement" required >
         <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Motivation</span>
       <select className="filds"  id="yearFounded" name="motivation" required >
         <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Disagreement</span>
       <select className="filds"  id="yearFounded" name="disagreement" required >
       <option></option>  
       <option value="1">More Yes</option>
         <option value="2">More No	</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Willingness to Learnd</span>
       <select className="filds"  id="yearFounded" name="willingLearn" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Year Prepared to Interview (context about company)	</span>
       <select className="filds"  id="yearFounded" name="preparedto" required >
       <option></option>  
       <option value="1">More Yes</option>
         <option value="2">More No	</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Adaptability</span>
       <select className="filds"  id="yearFounded" name="adabtability" required >
       <option ></option>
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Professionalism</span>
       <select className="filds"  id="yearFounded" name="proffessional" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Confidentiality (sensitive data from past experience)</span>
       <select className="filds"  id="yearFounded" name="confidentaility" required >
    <option></option>
       <option value="1">More Yes</option>
         <option value="2">More No	</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Responsiveness</span>
       <select className="filds"  id="yearFounded" name="responsiveness" required >
       <option></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Confidence</span>
       <select className="filds"  id="yearFounded" name="confidence" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Maturity</span>
       <select className="filds"  id="yearFounded" name="maturity" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Judgement</span>
       <select className="filds"  id="yearFounded" name="jundgement" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
    
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Goal Oriented</span>
       <select className="filds"  id="yearFounded" name="goalOriented" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Understanding of the business / industry	</span>
       <select className="filds"  id="yearFounded" name="understandingBusiness" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Salary negotiation skills</span>
       <select className="filds"  id="yearFounded" name="salaryNegotiations" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Questions asked</span>
       <select className="filds"  id="yearFounded" name="questionsAsked" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         <option value="4">4</option>
         </select>
     </label>
     <label className='input-label'>
       <span className = "filds-name">Openness to feedback</span>
       <select className="filds"  id="yearFounded" name="feedbackOpenness" required >
       <option ></option>
         <option value="1">1</option>
         <option value="2">2</option>
         <option value="3">3</option>
         </select>
     </label>
     <div className="btn-c">
     <button className="btn">Submit</button>
     </div>  
</form>  
):(<>
  <Numbers svv={data.svv}
            pnegativeMessages = {data.pnegativeMessages}
            positiveMessages = {data.positiveMessages}
            evalutions={data.evalutions}
            src={data.src}/>
            
        <div className="btn-c">
       <button className="btn" onClick={()=>showNumber(false)}>Back</button>
    </div>  
  </>)}



</>
)        
  }
export default FirstPart;