import React from 'react'
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';


function Numbers(props) { 
  const PrettoSlider = styled(Slider)({
    color: '#2A74F1',
    height: 20,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#52af77',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
  
  return (
    <div className='shownumbers'>
    <h1 className='h1'>
      scores:{props.svv}
      </h1>
      <div className='input-range'>
          {props.evalutions.map((i,index)=>( <div className='scoresss'>
          <span>{i.name}</span>
          <PrettoSlider  
          key={index}  
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={i.value*10}
      />
        </div>))}
    
      </div>
      <div>
      <div>
        <h1 className='h1'> Things that you are great.</h1>
        </div>
        <div>
          {props.positiveMessages.map((i,index)=>(<li className='li' > <input key={index} defaultValue={i} className='in'/></li>))}
        </div>
      </div>
      <div>
      <div>
        <h1  className='h2' >Things to focus on to make you more competitive.</h1>
        </div>
        <div>
        {props.pnegativeMessages.map((i,index)=>(<li className='li' > <input key={index} defaultValue={i} className='in'/></li>))}
        </div>
      </div>
      <div className='score_container'>
      <h3 >
      scores{props.svv}:
      </h3><span className='span'>
      {props.src}
      </span>
      </div>
   </div>
  )
}

export default Numbers