import './App.css';
import logo from "./img/logo.png"
import ApplicationCreate from "./ApplicationCreate"

function App() {
  return (
    <div className="App">
      <div className='container'>
        <div>
        <div className='logo-contain'>
          <img className='logo' src={logo}/>
          <h1 className='page-name'>Fair Interview</h1>
        </div>
        <h1 className='h1'>Score Interview participant</h1>
        </div>
      <ApplicationCreate/>
      </div>
     
    </div>
  );
}

export default App;
